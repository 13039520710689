import DBL from "../assets/logos/DoverBigLocal.png";
import GBCA from "../assets/logos/GBCAJudge.jpg";
import NCVO from "../assets/logos/NCVOLogo.png";
import NAG from "../assets/logos/nestAndGrow.png";
import TSL from "../assets/logos/ThirdSectorLeaders.png";
import BSL from "../assets/logos/BestStartupLogo.png";
import DTOM from "../assets/logos/DifferentTypesOfMindsLogo.png";

export default [
  {
    logoName: "Dover Big Local",
    path: DBL,
  },
  {
    logoName: "Great British Care Awards",
    path: GBCA,
  },
  {
    logoName: "National Council for Voluntary Organisations",
    path: NCVO,
  },
  {
    logoName: "Nest And Grow",
    path: NAG,
  },
  {
    logoName: "Third Sector Leaders",
    path: TSL,
  },
  {
    logoName: "Best Startup",
    path: BSL,
  },
  {
    logoName: "Different Types of Minds",
    path: DTOM,
  },
];
